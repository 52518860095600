<template>
  <div>
    <div class="header">
      <div><b>Jens Giesel</b> · Projects</div>
      <div class="social"><a href="mailto:hello@jensgiesel.de">@</a> · <a href="https://www.faz.net/redaktion/jens-giesel-15426194.html">F</a> · <a href="https://twitter.com/jensgiesel">X</a></div>
    </div>
    <div class="grid">
      <div v-for="project in data" :key="project.title" class="project">
        <div class="project_image">
          <a :href="project.url" target="_blank">
            <video muted loop playsinline v-if="isVideo(project.image)" class="video">
              <source :src="project.image" type="video/mp4">
            </video>
            <img :src="project.image" v-else />
          </a>
        </div>
        <div class="project_description">
          <div class="title">{{ project.title }}</div>
          <div class="type">{{ project.type }}</div>
          <div class="date">{{ project.date }}</div>
          <div class="link"><a :href="project.url" target="_blank">See more</a></div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import {csvParse} from 'd3'
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  data () {
    return {
      data: null
    }
  },
  mounted () {
    fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vSi1xrHdgJ2ZY6TMgutaYFqoww-xm2QHT8MIBQWDTcnDZjOX8Vd_L9MUGMs9J3k0dvtQPK8cPD4To3v/pub?output=csv')
    .then(r => r.text())
    .then(r => this.data = csvParse(r))

    setTimeout(() => {
      // console.log("Delayed for 1 second.");
      this.setupVideoObserver()
    }, "1000");

    this.setupVideoObserver()
  },
  methods: {
    isVideo(url) {
      return url.slice(-3) == 'mp4'
    },
    setupVideoObserver() {
      const videos = this.$el.querySelectorAll('.video');
      // console.log(videos)

      if ('IntersectionObserver' in window) {
        const videoObserver = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.play();
            } else {
              entry.target.pause();
            }
          });
        }, { threshold: 0.2 }); // Adjust the threshold as needed

        videos.forEach(video => videoObserver.observe(video));
      } else {
        console.warn("Your browser does not support IntersectionObserver. Consider a fallback or a polyfill.");
      }
    }
  }
}
</script>  

<style lang="scss">
@font-face {
  font-family: "Neutral";
  src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001251-a0dc899a4342b4cba3707e3bc356c858.eot");
  src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001251-a0dc899a4342b4cba3707e3bc356c858.eot?#iefix") format("embedded-opentype"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001251-a0dc899a4342b4cba3707e3bc356c858.woff2") format("woff2"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001251-a0dc899a4342b4cba3707e3bc356c858.woff") format("woff"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001251-a0dc899a4342b4cba3707e3bc356c858.svg#Typotheque_webfonts_service") format("svg");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "Neutral Regular";
  src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001251-a0dc899a4342b4cba3707e3bc356c858.eot");
  src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001251-a0dc899a4342b4cba3707e3bc356c858.eot?#iefix") format("embedded-opentype"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001251-a0dc899a4342b4cba3707e3bc356c858.woff2") format("woff2"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001251-a0dc899a4342b4cba3707e3bc356c858.woff") format("woff"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001251-a0dc899a4342b4cba3707e3bc356c858.svg#Typotheque_webfonts_service") format("svg"); }

@font-face {
  font-family: "Neutral";
  src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001253-437cebfd5f09b8d826c61e63f0551b37.eot");
  src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001253-437cebfd5f09b8d826c61e63f0551b37.eot?#iefix") format("embedded-opentype"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001253-437cebfd5f09b8d826c61e63f0551b37.woff2") format("woff2"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001253-437cebfd5f09b8d826c61e63f0551b37.woff") format("woff"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001253-437cebfd5f09b8d826c61e63f0551b37.svg#Typotheque_webfonts_service") format("svg");
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: "Neutral Medium";
  src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001253-437cebfd5f09b8d826c61e63f0551b37.eot");
  src: url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001253-437cebfd5f09b8d826c61e63f0551b37.eot?#iefix") format("embedded-opentype"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001253-437cebfd5f09b8d826c61e63f0551b37.woff2") format("woff2"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001253-437cebfd5f09b8d826c61e63f0551b37.woff") format("woff"), url("https://s3-eu-west-1.amazonaws.com/fonts-ireland.typotheque.com/WF-027513-009219-001253-437cebfd5f09b8d826c61e63f0551b37.svg#Typotheque_webfonts_service") format("svg"); }

  html, body {
    margin: 0;
    padding: 0;
  }

  b {
    font-family: "Neutral Medium", Helvetica, Arial, sans-serif;
    font-weight: normal;
  }

  a {
    color: #666;
    text-decoration: none;
  }

#app {
  font-family: "Neutral Regular", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  display: flex;
  padding: 20px;
  max-width: 1000px;
  margin: auto;
}

.header {
  padding-bottom: 2em;
  display: flex;
  flex-direction: row;

  div {
    width: 50%;
  }

  .social {
    text-align: right;
  }
}

.grid {
  // max-width: 800px;
  margin: auto;

  .project {
    border-top: 2px solid #000;
    flex-direction: column;
    padding-top: 1em;
    margin-bottom: 2em;

    &_image {
      margin-bottom: 10px;
    }

    &_description {
      .title {
        font-family: "Neutral Medium", Helvetica, Arial, sans-serif;
      }
    }

    video, img {
      max-width: 100%;
    }
  } 
}
</style>
